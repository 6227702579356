import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-companyselect',
  templateUrl: './companyselect.component.html',
  styleUrls: ['./companyselect.component.scss']
})
export class CompanyselectComponent implements OnInit {

  client: any = {}
  clientId:string = this.route.snapshot.params.id?this.route.snapshot.params.id:"";
  constructor(private spinner: NgxSpinnerService,private route:ActivatedRoute,private router:Router, ) { }

  ngOnInit() {
    this.spinner.show();
    if(this.clientId != ""){
      this.client.id = localStorage.getItem('companyId')
      this.client.name = localStorage.getItem('companyName')
    }else{
      this.router.navigate(['/company-list'])
    }
    this.spinner.hide();
  }

  clickCompany(){
    this.spinner.show();
    this.router.navigate(['/company-monthly-list-admin'])
    this.spinner.hide();
  }

  clickIndividual(){
    this.spinner.show();
    this.router.navigate(['/quarter-list'])
    this.spinner.hide();
  }

}
