import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private router:Router, private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  clickCompany(){
    this.spinner.show()
    this.router.navigate(['/company-monthly-list']);
    this.spinner.hide()
  }

  clickIndividual(){
    this.spinner.show()
    this.router.navigate(['/individual-quarterly']);
    this.spinner.hide()
  }

}
