import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NewRequestIndividual } from './model/newrequestindividual';
import { NewRequestCompany } from './model/newrequestcompany';
import { Individual } from './model/individual';
import { Quarter } from './model/quarter';
import { User } from './model/user';
import { isDevMode } from '@angular/core'
import { Company } from './model/company';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  apiUrl = '';

  constructor(private _http:HttpClient) { 
    if(isDevMode()){
      // this.apiUrl = 'http://localhost:3000/api/';
      this.apiUrl = 'http://13.233.126.17:3000/api/';
    }else{
      //ADD DOMAIN FOR SPYDER
      // this.apiUrl = 'http://13.233.126.17:3000/api/';
    }
  }

  // USER
  addUser(user: User):Observable<User>{
    return this._http.post<User>(this.apiUrl + `user`,user);
  }

  getAllUsers(){
    return this._http.get<User[]>(this.apiUrl+'user').toPromise();
  }

  getUser(userId: string){
    return this._http.get<User>(this.apiUrl+`user/${userId}`).toPromise();
  }

  updateUser(user: User){
    return this._http.put(this.apiUrl+`user/${user.id}`,user).toPromise();
  }

  //NEW REQUEST INDIVIDUAL
  getAllNewRequestIndividual(){
    return this._http.get<NewRequestIndividual[]>(this.apiUrl+'new-request-individual').toPromise();
  }

  addNewRequestIndividual(individual: NewRequestIndividual):Observable<NewRequestIndividual>{
    return this._http.post<NewRequestIndividual>(this.apiUrl + `new-request-individual`,individual);
  }

  getNewRequestIndividual(id: string){
    return this._http.get<NewRequestIndividual>(this.apiUrl+`new-request-individual/${id}`).toPromise();
  }

  //NEW REQUEST COMPANY
  getAllNewRequestCompany(){
    return this._http.get<NewRequestCompany[]>(this.apiUrl+'new-request-company').toPromise();
  }

  getNewRequestCompany(id: string){
    return this._http.get<NewRequestCompany>(this.apiUrl+`new-request-company/${id}`).toPromise();
  }

  addNewRequestCompany(newRequest: NewRequestCompany):Observable<NewRequestCompany>{
    return this._http.post<NewRequestCompany>(this.apiUrl+`new-request-company`,newRequest);
  }

  //UPLOAD FILE TO S3
  uploadFileToS3(data){
    return this._http.put(this.apiUrl+`new-file-upload`,data).toPromise();
  }

  //HISTORY
  getNewRequestCompanyHistory(clientId: string){
    return this._http.get<NewRequestCompany[]>(this.apiUrl+`company-history/${clientId}`).toPromise();
  }

  getNewRequestIndividualHistory(clientId: string){
    return this._http.get<NewRequestIndividual[]>(this.apiUrl+`individual-history/${clientId}`).toPromise();
  }

  //UPDATE NEW REQUEST STATUS FOR COMPANY
  updateNewRequestCompnayStatus(requestId: string, status:any){
    return this._http.put(this.apiUrl+`update-status/company/${requestId}`,status).toPromise();
  }

  //UPDATE NEW REQUEST STATUS FOR INDIVIDUAL
  updateNewRequestIndividualStatus(requestId: string, status:any){
    return this._http.put(this.apiUrl+`update-status/individual/${requestId}`,status).toPromise();
  }

  //GET LIST OF CLIENTS
  getAllClientsList(){
    return this._http.get<any[]>(this.apiUrl+'client-list').toPromise();
  }

  // NEW UPLOAD INDIVIDUAL & COMPANY
  uploadReportToS3(data){
    return this._http.put(this.apiUrl+`new-report-upload`,data).toPromise();
  }

  // NEW UPLOAD INDIVIDUAL & COMPANY ATTACHMENTS
  uploadAttachmentsToS3(data){
    return this._http.put(this.apiUrl+`attachment`,data).toPromise();
  }



  // INDIVIDUAL

  getIndividual(id: string){
    return this._http.get<Individual>(this.apiUrl+`individual/${id}`).toPromise();
  }

  addEmptyIndividualToAddReport(individual:Individual):Observable<Individual>{
    return this._http.post<Individual>(this.apiUrl+`individual`,individual);
  }

  uploadSocialSMImagesToS3(data){
    return this._http.put(this.apiUrl+`social-image/sm`,data).toPromise();
  }
  uploadSocialSNImagesToS3(data){
    return this._http.put(this.apiUrl+`social-image/sn`,data).toPromise();
  }
  uploadSocialFGImagesToS3(data){
    return this._http.put(this.apiUrl+`social-image/fg`,data).toPromise();
  }

  updateIndividual(individual: Individual){
    return this._http.put(this.apiUrl+`individual/${individual.id}`,individual).toPromise();
  }

  // QUARTER VISE INDIVIDUAL LIST
  getQuarterViseIndividualList(clientId){
    return this._http.get<any[]>(this.apiUrl+`quarter-vise-list/${clientId}`).toPromise();
  }

  getAdminQuarterViseIndividualList(clientId){
    return this._http.get<any[]>(this.apiUrl+`quarter-vise-list/admin/${clientId}`).toPromise();
  }

  getIndividualListByQuarterAndYear(clientId,quarter,year){
    return this._http.get<any[]>(this.apiUrl+`quarter-vise-list/${clientId}/${quarter}/${year}`).toPromise();
  }

  // DATA FOR QUARTER VISUAL CHARTS DASHBOARD
  getRiskScoreReport(clientId,quarter,year){
    return this._http.get<any[]>(this.apiUrl+`quarter-report/riskscore/${clientId}/${quarter}/${year}`).toPromise();
  }

  getStateReport(clientId,quarter,year){
    return this._http.get<any[]>(this.apiUrl+`quarter-report/state/${clientId}/${quarter}/${year}`).toPromise();
  }

  getGenderReport(clientId,quarter,year){
    return this._http.get<any[]>(this.apiUrl+`quarter-report/gender/${clientId}/${quarter}/${year}`).toPromise();
  }


  // DATA FOR OVERALL VISUALS DASHBOARD
  
  getOverallIndividualRiskScoreReport(clientId){
    return this._http.get<any[]>(this.apiUrl+`individual-summary/riskscore/${clientId}`).toPromise();
  }

  getOverallCompanyRiskScoreReport(clientId){
    return this._http.get<any[]>(this.apiUrl+`company-summary/riskscore/${clientId}`).toPromise();
  }

  getOverallIndividualStateReport(clientId){
    return this._http.get<any[]>(this.apiUrl+`individual-summary/state/${clientId}`).toPromise();
  }

  getOverallIndividualGenderReport(clientId,startDate,endDate){
    return this._http.get<any[]>(this.apiUrl+`individual-summary/gender/${clientId}/${startDate}/${endDate}`).toPromise();
  }

  // getOverallIndividualGenderReport(clientId){
  //   return this._http.get<any[]>(this.apiUrl+`individual-summary/gender/${clientId}`).toPromise();
  // }

  getOverallIndividualsCount(clientId){
    return this._http.get<any[]>(this.apiUrl+`individual-summary/total/${clientId}`).toPromise();
  }

  getOverallCompaniesCount(clientId){
    return this._http.get<any[]>(this.apiUrl+`company-summary/total/${clientId}`).toPromise();
  }

  getOverallNewRequestIndividualsCount(clientId){
    return this._http.get<any[]>(this.apiUrl+`individual-summary/newrequest/total/${clientId}`).toPromise();
  }

  getOverallNewRequestCompaniesCount(clientId){
    return this._http.get<any[]>(this.apiUrl+`company-summary/newrequest/total/${clientId}`).toPromise();
  }

  getOverallNewRequestIndividualsStatusCount(clientId){
    return this._http.get<any[]>(this.apiUrl+`individual-summary/newrequest/status/${clientId}`).toPromise();
  }

  getOverallNewRequestCompaniesStatusCount(clientId){
    return this._http.get<any[]>(this.apiUrl+`company-summary/newrequest/status/${clientId}`).toPromise();
  }

  // COMPANY

  getCompany(id: string){
    return this._http.get<Company>(this.apiUrl+`company/${id}`).toPromise();
  }

  addEmptyCompanyToAddReport(company:Company):Observable<Company>{
    return this._http.post<Company>(this.apiUrl+`company`,company);
  }

  updateCompany(company: Company){
    return this._http.put(this.apiUrl+`company/${company.id}`,company).toPromise();
  }


  // UPLOAD COMPANY LOGO
  uploadCompanyLogoToS3(data){
    return this._http.put(this.apiUrl+`company-logo`,data).toPromise();
  }

  getMonthViseCompanyList(clientId){
    return this._http.get<any[]>(this.apiUrl+`month-vise-list/${clientId}`).toPromise();
  }

  getAdminMonthViseCompanyList(clientId){
    return this._http.get<any[]>(this.apiUrl+`month-vise-list/admin/${clientId}`).toPromise();
  }


  // NOTIFICATIONS
  getNewAdminNotifications(){
    return this._http.get<any[]>(this.apiUrl+`admin-notification`).toPromise()
  }

  getAllAdminNotification(){
    return this._http.get<any[]>(this.apiUrl+`admin-notification/all`).toPromise()
  }

  getNewClientNotifications(clientId){
    return this._http.get<any[]>(this.apiUrl+`client-notification/${clientId}`).toPromise()
  }

  getAllClientNotification(clientId){
    return this._http.get<any[]>(this.apiUrl+`client-notification/all/${clientId}`).toPromise()
  }

  updateAdminNotificationStatus(id){
    return this._http.put(this.apiUrl+`admin-notification`,{id:id}).toPromise()
  }

  updateClientNotificationStatus(id){
    return this._http.put(this.apiUrl+`client-notification`,{id:id}).toPromise()
  }










  // QUARTER  VISUAL //NOT REQUIRED NOW
  addQuarterVisualInfo(quarter:Quarter){
    return this._http.post<Quarter>(this.apiUrl+`quarter-visual`,quarter).toPromise();
  }

  uploadQuarterVisualImage(data){
    return this._http.put(this.apiUrl+`quarter-visual-upload`,data).toPromise();
  }

  getVisuals(clientId,quarter,year){
    return this._http.get<any[]>(this.apiUrl+`quarter-visual/${clientId}/${quarter}/${year}`).toPromise();
  }


}