import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class HttpinterceptorService implements HttpInterceptor{

  constructor(private authService: AuthenticationService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if (localStorage.getItem('token')) {
      req = req.clone({
        setHeaders: {
          // UserRole:this.authService.getUserRole(),
          // User: this.authService.getUserId(),
          Authorization: 'Bearer '+localStorage.getItem('token')
        }
      })
    }

    return next.handle(req);
  }
}
