import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ToastrModule } from 'ngx-toastr';
// import { AngularEditorModule } from '@kolkov/angular-editor';
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { HomeComponent } from './clientUI/home/home.component';
import { SelectComponent } from './clientUI/select/select.component';
import { CompanyselectComponent } from './adminUI/companyselect/companyselect.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpinterceptorService } from './httpinterceptor.service';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  imports: [
    // AngularEditorModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, HomeComponent, SelectComponent,CompanyselectComponent],
  providers: [
    NotificationsComponent,
    {  
      provide:HTTP_INTERCEPTORS, useClass:HttpinterceptorService, multi:true 
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
