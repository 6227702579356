import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss','./home.component.css']
})
export class HomeComponent implements OnInit {

  hidden: boolean = true
  username : string ="";
  password : string = "";

  constructor(private loginservice: AuthenticationService, private router:Router) { }

  ngOnInit(): void {
  }

  togglePassword(){
    this.hidden = !this.hidden
  }
  login(){
    if(!this.username.trim() || !this.password.trim()){
      alert('Please provide email and password');
    }
    else{
      this.loginservice.authenticate(this.username,this.password).then(
        (token) => {
          let user = jwt_decode(token)
          user = user.user
          var userToken = token;   
          localStorage.setItem('token',userToken)
          user.isAdmin?this.router.navigate(['/company-list']):this.router.navigate(['/select'])
        },
        error => {
          alert("Wrong Credentials")
          console.log(error)
        }
      )
      .catch(error => {
        alert("Wrong Credentials")
        console.log(error)
      })      
    }
  }
}
