import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from 'src/app/authentication.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/select",
    title: "Home",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/overall-visual-dashboard",
    title: "Dashboard",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/company-monthly-list",
    title: "Company List",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/individual-quarterly",
    title: "Individual List",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/history",
    title: "History",
    icon: "icon-single-copy-04",
    class: ""
  }
];

export const ADMINROUTES: RouteInfo[] = [
  {
    path: "/company-list",
    title: "Client List",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/company-monthly-list-admin",
    title: "Company",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/quarter-list",
    title: "Individual",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/new-data",
    title: "New Requests",
    icon: "icon-email-85",
    class: ""
  }
];

export const SUPERADMINROUTES: RouteInfo[] = [
  {
    path: "/company-list",
    title: "Client List",
    icon: "icon-chart-pie-36",
    class: ""
  },
  {
    path: "/new-data",
    title: "New Requests",
    icon: "icon-email-85",
    class: ""
  },
  {
    path: "/add-user",
    title: "User",
    icon: "icon-email-85",
    class: ""
  },
  {
    path: "/user-list",
    title: "User List",
    icon: "icon-email-85",
    class: ""
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    
    if(this.authService.getUserRole() == "SUPERADMIN"){
      this.menuItems = SUPERADMINROUTES.filter(menuItem => menuItem);
    }else if(this.authService.getUserRole() == "ADMIN"){
      this.menuItems = ADMINROUTES.filter(menuItem => menuItem);
    }else{
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    // if(this.authService.currentUser.isAdmin){
    //   this.menuItems = ADMINROUTES.filter(menuItem => menuItem);
    // }else{
    //   this.menuItems = ROUTES.filter(menuItem => menuItem);
    // }
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
