import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from "@auth0/angular-jwt";
import { DataServiceService } from './data-service.service';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  jwtHelper = new JwtHelperService();
  // currentUser: User = new User()

  constructor(
    private router:Router,private httpClient:HttpClient, private dataService: DataServiceService) { 
      // this.isUserLoggedIn();
      // console.log(this.currentUser.id)
  }

  authenticate(email, password) {
    return this.httpClient.post<any>(this.dataService.apiUrl+'login',{
      email:email,
      password:password
    }).toPromise()
  }

  getToken(){
    var token = localStorage.getItem('token')
    return token;
  }

  getUserId(){
    var user = this.decryptToken(this.getToken())
    // user = user.user
    return user.id
  }

  getUserName(){
    var user = this.decryptToken(this.getToken())
    // user = user.user
    // alert(JSON.stringify(user))
    return user.name
  }

  isTokenValid(){
    return !this.jwtHelper.isTokenExpired(this.getToken())?true:false;
  }

  decryptToken(token){
    var user = jwt_decode(token)
    return user.user
  }

  getUserRole(){
    if(this.isTokenValid()){
      var user = this.decryptToken(this.getToken());
      return user.role;
    }
    // return this.currentUser.role;
  }

  isUserAdmin(){
    if(this.isTokenValid()){
      var user = this.decryptToken(this.getToken());
      return user.isAdmin;
    }
    // return this.currentUser.isAdmin;
  }

  // checkUser(){
  //   // if(this.currentUser == null || this.currentUser == undefined || !this.currentUser || this.currentUser.id==undefined ){
  //     try{
  //       if(this.isTokenValid()){

  //       }
  //       // var token = localStorage.getItem('token')
  //       // if(!this.jwtHelper.isTokenExpired(token)){
  //       //   var user = jwt_decode(token)
  //       //   user = user.user
  //       //   // var userToken = token;   
  //       //   // this.currentUser.name = user.name;
  //       //   // this.currentUser.email = user.email;
  //       //   // this.currentUser.id = user.id;
  //       //   // this.currentUser.role = user.role;
  //       //   // this.currentUser.token = userToken;
  //       //   // this.currentUser.loggedIn = true;
  //       //   // this.currentUser.isAdmin = user.isAdmin;
  //       //   // this.currentUser.contact = user.contact;
  //       // }
  //     }catch(err){      
  //       this.router.navigate(['/home'])  
  //     }
  //   // }
  // }

  isUserLoggedIn() {
    
    if(this.isTokenValid()){
      return true;
    }else{
      return false;
    }
    
    // try{
    //   var token = localStorage.getItem('token')
    //   return !this.jwtHelper.isTokenExpired(token)
    // }
    // catch(err){
    //   // this.router.navigate(['/home'])
    //   return false;
    // }
    
  }

  logOut() {
    // this.currentUser = new User();
    // localStorage.removeItem('user')
    localStorage.removeItem('token')
  }
}
